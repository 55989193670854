.wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    flex-direction: column;
    padding-top: 50px;

    &.screenshot {
        height: 705px;
        padding: 0px;
        justify-content: center;

        :global {
            img.crest {
                height: 530px;
            }
        }
    }
}

.btn {
    padding: 16px 32px;
    max-width: 300px;
    padding-top: 50px;
    margin: auto;
}

.laoder {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}
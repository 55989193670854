.footer {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

.wrapper {
    @media (max-width: 768px) {
        right: auto;
        width: 100%;
        justify-content: center;
    }

    height: calc(154px * var(--scale));
    padding: calc(17px * var(--scale)) calc(80px * var(--scale));
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: calc(-154px * var(--scale));
}

.logo {
    position: relative;
    overflow: hidden;
    // max-width: calc(205px* var(--scale));
    // height: calc(120px* var(--scale));

    img {
        height: 100%;
        width: auto;
    }
}
.wrapper {
    @media (max-width: 1024px) {
        justify-content: center;
    }

    cursor: pointer;
    height: calc(154px * var(--scale));
    padding: calc(17px * var(--scale)) calc(80px * var(--scale));
    background-color: var(--page-header);
    font-size: calc(66px * var(--scale));
    font-weight: 700;
    text-align: center;
    color: var(--page-text);
    letter-spacing: calc(0.977px * var(--scale));
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 1124px) {
        font-size: calc(97px * var(--scale));
    }
}

.logo {
    position: relative;
    max-width: calc(205px * var(--scale));
    height: calc(120px * var(--scale));

    img {
        height: 100%;
        width: auto;
    }

    @media (max-width: 1024px) {
        &.right {
            display: none;
        }
    }
}

@media (max-width: 1024px) {
    .title {
        display: none;
    }
}